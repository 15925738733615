import React from 'react';
import ElenaPhoto from '../../logo/Elena.jpg'
import IvanPhoto from '../../logo/Ivan.jpg'

const About = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center px-5 p-4">
                <div className="text-mainColor2 text-3xl md:text-5xl font-medium">О нас</div>
            </div>
            <div className="flex justify-center p-4">
                <div className="flex flex-col justify-center items-center ">
                    <div className="flex justify-center py-2">
                        <div className="flex flex-col justify-center items-center ">
                            <div className="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
                                <div className="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[50%] px-8">
                                    <div className="flex flex-col md:flex-row items-center justify-center  ">
                                        <div className="  items-center justify-center flex py-4">
                                            <div className="flex flex-col  items-center justify-center text-center">
                                                <img src={ElenaPhoto} alt="" className="rounded-full w-[33%] md:w-[60%] hover:scale-110" />
                                                <div className="text-stone-600 font-medium m-2"> Макарова
                                                    Елена Федоровна</div>
                                                <div className="font-bold">Врач стоматолог</div>
                                                <div className="text-mainColor italic">Терапевтическая стоматология</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-hidden w-full m-4 flex justify-center bg-white rounded-lg  md:w-[50%] px-8">
                                    <div className="flex flex-col md:flex-row items-center justify-center  ">
                                        <div className="  items-center justify-center flex py-2">
                                            <div className="flex flex-col  items-center justify-center text-center ">
                                                <img src={IvanPhoto} alt=""
                                                    className="rounded-full w-[33%] md:w-[60%] hover:scale-110 " />
                                                <div className="text-stone-600 font-medium m-2">Горлов
                                                    Иван Алексеевич</div>
                                                <div className="font-bold">Главный врач,врач-стоматолог</div>
                                                <div className="text-mainColor italic">Протезирование</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-stone-600 my-4 md:font-medium text-xl text-center w-3/4 md:w-1/2">Мы открыли клинику "STOM_ID" для того, чтобы каждый пациент, чувствовал себя максимально комфортно,это не просто стоматология - это наше семейной дело, наша душа. А пациенты клиники - гости, а как известно, для гостей всё самое лучшее!</div>
                </div>
            </div>
        </div>
    );
}

export default About;
