import React from 'react';
import logo from '../../logo/logo.png'
const Footer = () => {
    return (
        <div>
            <footer className="text-gray-600 body-font">
                <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                    <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <img src={logo} alt="" className='h-[40px] w-[50px] p-[5px]' />
                        <span className="ml-3 text-xl">Stom_id</span>
                    </a>
                    <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2022 Stom_id —
                        <a href="https://twitter.com/knyttneve" className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">Все права защищены</a>
                    </p>
                    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                        <div className=" text-center inline-flex text-xl">
                            <a href="https://vk.com/stom_id_clinic" target="blank" className="w-8 h-8 inline-block rounded-full pt-[5px] text-sky-700 hover:text-sky-900">
                                <i className="fa fa-vk fa-lg"></i>
                            </a>
                            <a href="https://www.instagram.com/stom_id_clinic" target="blank" className="w-8 h-8 inline-block rounded-full pt-[5px] text-red-500 hover:text-red-700">
                                <i className="fa fa-instagram fa-lg"></i>
                            </a>
                            <a href="https://wa.me/+79995127310" target="blank" className="w-8 h-8 inline-block rounded-full pt-[5px] text-green-500 hover:text-green-700">
                                <i className="fa fa-whatsapp fa-lg"></i>
                            </a>
                            <a href='tel:+79995127310' className="w-8 h-8 inline-block rounded-full pt-[5px] text-green-500 hover:text-green-700">
                                <i className="fa fa-mobile fa-lg"></i>
                            </a>
                        </div>
                    </span>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
