import React from 'react';

const WorksIt = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center px-5 p-4">
                <div className="text-mainColor text-3xl md:text-5xl font-medium p-16">МЫ ИСПОЛЬЗУЕМ</div>
            </div>
            <section className="text-gray-600 body-font">
                <div className="container px-5 p-4 mx-auto flex flex-wrap">
                    <div className="flex relative pt-10 pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-mainColor text-white relative z-10 title-font font-medium text-sm">1</div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row animate-pulse">
                            <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-mainColor2 rounded-full inline-flex items-center justify-center">
                                <i className="fa fa-microscope fa-2x"></i>
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Микроскоп с шестикратным увеличением и фотоаппаратом</h2>
                                <p className="leading-relaxed">Это позволяет увидеть любой нюанс и показать его вам в деталях.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-mainColor text-white relative z-10 title-font font-medium text-sm">2</div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row animate-pulse">
                            <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-mainColor2 rounded-full inline-flex items-center justify-center">
                                <i className="fa-solid fa-hand-holding-medical fa-2x"></i>
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Максимальный комфорт</h2>
                                <p className="leading-relaxed">При каждой процедуре вы находитесь в положении лёжа в расслабленном состоянии.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-mainColor text-white relative z-10 title-font font-medium text-sm">3</div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row animate-pulse">
                            <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-mainColor2 rounded-full inline-flex items-center justify-center">
                                <i className="fa-sharp fa-solid fa-tooth fa-2x"></i>
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Коффердам</h2>
                                <p className="leading-relaxed">Делает процесс лечения более безопасным и качественным</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default WorksIt;
