import React from 'react';
import video from '../../logo/video.mp4'
const Find = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center px-5 p-4">
                <div className="text-mainColor2 text-3xl md:text-5xl font-medium p-16">Как нас найти</div>
            </div>
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container mx-auto">
                    <div className="flex flex-wrap -m-12" >
                        <iframe src="https://yandex.ru/map-widget/v1/-/CCUVnHeBDA scroll=false" width="100%" height='400' frameborder="1" allowfullscreen="true"></iframe>
                        <div className=" flex flex-col">
                            <div className=" md:w-auto bg-white rounded-lg p-8 flex flex-col md:ml-auto mt-10 md:mt-0 shadow-2xl">
                                <video muted loop controls autoPlay className="w-full aspect-video" src={video}></video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Find;
