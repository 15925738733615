import React from 'react';
import mainPhoto1 from '../../logo/1.1.png'

const Main = () => {
    return (
        <div>
            <div className="bg-animation">
                <div className="flex justify-center flex-row">
                    <div className="flex flex-row justify-center ">
                        <img className='text-center items-center justify-center mt-20 md:mt-12 hidden sm:block' src={mainPhoto1} alt="" />
                    </div>
                    <div className="flex flex-col justify-center place-content-center text-[12px]">
                        <div className="">
                            <div className="flex flex-col justify-center sm:justify-center items-center">

                                <h1 className="text-mainColor2 font-bold font-serif text-4xl  sm:text-6xl ">Stom_id</h1>
                                <p className="justify-center text-xl text-mainColor md:w-[250px] mt-5 items-center">Cтоматологическая клиника</p>
                                <p className="text-justify text-stone-600 md:w-[250px] mt-5 text-base">Часы работы:<br />
                                    Ежедневно с 10-00 до 20-00</p>
                                <p className="text-justify text-stone-600 md:w-[250px] mt-5 text-base">Для Вас много учимся и много работаем!</p>
                                <a href="https://app.1denta.ru/booking/booking?orgid=8764#/main" target="blank" >
                                    <button className="mt-5 px-6 py-1 text-lg mb-4 rounded-2xl text-white hover:bg-mainColor2 bg-mainColor animate-bounce">Записаться</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
