import React from 'react';
import konsult from '../../logo/konsult.jpg'
import otbel from '../../logo/otbel.jpg'
import protez from '../../logo/protez.jpg'
import le4 from '../../logo/le4.jpg'

const Price = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center px-5 p-4 ">
                <div className="text-mainColor text-3xl md:text-5xl font-medium p-16">Наши услуги</div>
            </div>
            <section className="text-gray-600 body-font">
                <div className="container px-5 p-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full shadow-2xl hover:scale-110">
                            <a className="block relative h-48 rounded overflow-hidden">
                                <img alt="ecommerce" className="object-cover object-center w-full h-full block" src={konsult} />
                            </a>
                            <div className="mt-4">
                                <h2 className="text-gray-900 title-font text-lg font-medium">Консультация</h2>
                                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Осмотр врача стоматолога. Выявление заболеваний на ранних стадиях.</h3>
                                <p className="mt-1">от 300 руб.</p>
                                <a href="https://app.1denta.ru/booking/booking?orgid=8764#/main" target="blank" >
                                    <button className="mt-5 px-6 py-1 text-lg  rounded-2xl text-white hover:bg-mainColor2 bg-mainColor ">Записаться</button>
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full shadow-2xl hover:scale-110">
                            <a className="block relative h-48 rounded overflow-hidden">
                                <img alt="ecommerce" className="object-cover object-center w-full h-full block" src={otbel} />
                            </a>
                            <div className="mt-4">
                                <h2 className="text-gray-900 title-font text-lg font-medium">Гигиена и отбеливание</h2>
                                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Профессиональная гигиена и профилактика всей полости рта.</h3>
                                <p className="mt-1">от 3000 т.руб.</p>
                                <a href="https://app.1denta.ru/booking/booking?orgid=8764#/main" target="blank" >
                                    <button className="mt-5 px-6 py-1 text-lg  rounded-2xl text-white hover:bg-mainColor2 bg-mainColor ">Записаться</button>
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full shadow-2xl hover:scale-110">
                            <a className="block relative h-48 rounded overflow-hidden">
                                <img alt="ecommerce" className="object-cover object-center w-full h-full block" src={protez} />
                            </a>
                            <div className="mt-4">
                                <h2 className="text-gray-900 title-font text-lg font-medium">Протезирование</h2>
                                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Детальная диагностика и проектирование результата</h3>
                                <p className="mt-1">от 10 000 т.руб</p>
                                <a href="https://app.1denta.ru/booking/booking?orgid=8764#/main" target="blank" >
                                    <button className="mt-5 px-6 py-1 text-lg  rounded-2xl text-white hover:bg-mainColor2 bg-mainColor ">Записаться</button>
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full shadow-2xl hover:scale-110">
                            <a className="block relative h-48 rounded overflow-hidden">
                                <img alt="ecommerce" className="object-cover object-center w-full h-full block" src={le4} />
                            </a>
                            <div className="mt-4">
                                <h2 className="text-gray-900 title-font text-lg font-medium">Лечение</h2>
                                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Сохранение и эстетическое восстановление родных зубов</h3>
                                <p className="mt-1">от 2 700 т.руб</p>
                                <a href="https://app.1denta.ru/booking/booking?orgid=8764#/main" target="blank" >
                                    <button className="mt-5 px-6 py-1 text-lg  rounded-2xl text-white hover:bg-mainColor2 bg-mainColor ">Записаться</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Price;
