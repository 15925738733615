import Nav from './components/nav/nav'
import Main from './components/main/main'
import About from './components/about/about'
import WorksIt from './components/works/worksIt'
import Price from './components/price/price'
import Find from './components/find/find'
import Footer from './components/footer/footer'

function App() {
  return (
    <div>
      <Nav />
      <Main />
      <WorksIt />
      <About />
      <Price />
      <Find />
      <Footer />
    </div >
  );
}

export default App;
